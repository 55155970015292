import { ORDER_STATUS_MAP, ORDER_TYPE_MAP } from '@Cost/constants'
import { getTimeTableColumn } from '@/utils/common/tableColumn'
const getOrderStatusText = (status) => {
  let text = ''
  Object.keys(ORDER_STATUS_MAP).forEach((key) => {
    if (ORDER_STATUS_MAP[key].key === status.toString()) {
      text = ORDER_STATUS_MAP[key].label
    }
  })
  return text
}
const getOrderTypeText = (type) => {
  let text = ''
  Object.keys(ORDER_TYPE_MAP).forEach((key) => {
    if (ORDER_TYPE_MAP[key].key === type.toString()) {
      text = ORDER_TYPE_MAP[key].label
    }
  })
  return text
}
export default {
  data () {
    return {
      orderProductList: [],
    }
  },
  computed: {
    columns () {
      return [
        {
          field: 'id',
          title: '订单号',
          slots: {
            default: ({ row }) => {
              return [
                <list-body-cell-wrap copy row={row} field="id" title={row.id} />,
              ]
            },
          },
        },
        {
          field: 'product_type',
          title: '产品',
          slots: {
            default: ({ row }) => {
              return [
                <span row={row} title={this.getOrderProductsText(row.product_type)}>
                  {this.getOrderProductsText(row.product_type)}
                </span>,
              ]
            },
          },
        },
        {
          field: 'instance_ids',
          title: '实例ID',
          slots: {
            default: ({ row }) => {
              let instance_ids = ''
              if (row.instance_ids?.join(',')) {
                instance_ids = row.instance_ids.join(',')
              } else {
                instance_ids = '-'
              }
              return [
                <span row={row} title={row.instance_ids}>
                  {instance_ids}
                </span>,
              ]
            },
          },
        },
        {
          field: 'order_type',
          title: '类型',
          slots: {
            default: ({ row }) => {
              return [<span>{getOrderTypeText(row.order_type)} </span>]
            },
          },
        },
        getTimeTableColumn({
          field: 'created_at',
        }),
        getTimeTableColumn({
          field: 'paid_at',
          title: '支付/开通时间',
        }),
        {
          field: 'order_status',
          title: '状态',
          slots: {
            default: ({ row }) => {
              return [<span>{getOrderStatusText(row.order_status)} </span>]
            },
          },
        },
        {
          field: 'unit_price_list',
          title: '价格',
          slots: {
            default: ({ row }) => {
              return [<span>{row.unit_price_list}元</span>]
            },
          },
        },
        {
          field: 'amount_due_list',
          title: '应付金额',
          slots: {
            default: ({ row }) => {
              return [<span>{row.amount_due_list}元</span>]
            },
          },
        },
      ]
    },
  },
  created () {
    this.getOrderProducts()
  },
  methods: {
    // 获取产品类型label
    getOrderProductsText (orderProducts) {
      let text = ''
      this.orderProductList.forEach(item => {
        if (item.key === orderProducts) {
          text = item.label
        }
      })
      return text
    },
    // 获取产品类型
    getOrderProducts () {
      this.$http.get('/v2/products/order-products').then((result) => {
        this.orderProductList = result?.data.data || []
        this.orderProductList.forEach(item => {
          item.label = item.name
          item.key = item.code
        })
        if (!this.list) return
        this.list.filterOptions.product_type.items = this.orderProductList
      })
    },
  },
}
