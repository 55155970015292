<template>
  <page-list
    show-ext-tags
    :list="list"
    :columns="columns"
    :placeholder="placeholder"
    :single-actions="singleActions"
    :showSearchbox="showSearchbox"
    defaultSearchKey="product_type"
    :showGroupActions="showGroupActions"
    :ext-tag-params="{ service: 'identity' }"
    :hideRowselect="true" />
  <!--
    :export-data-options="exportDataOptions" -->
</template>

<script>
import ListMixin from '@/mixins/list'
import WindowsMixin from '@/mixins/windows'
import { getTimeRangeFilter } from '@/utils/common/tableFilter'
// import { getEnabledSwitchActions } from '@/utils/common/tableActions'
import GlobalSearchMixin from '@/mixins/globalSearch'
import SingleActionsMixin from '../mixins/singleActions'
import ColumnsMixin from '../mixins/columns'

export default {
  name: 'OrderList',
  mixins: [
    WindowsMixin,
    ListMixin,
    GlobalSearchMixin,
    ColumnsMixin,
    SingleActionsMixin,
  ],
  props: {
    id: String,
    getParams: {
      type: Object,
    },
    isAllowCreate: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      list: this.$list.createList(this, {
        id: this.id,
        resource: 'order',
        apiVersion: 'v2',
        getParams: this.getParam,
        sortKeys: [],
        filterOptions: {
          id: {
            label: '订单号',
          },
          instance_id: {
            label: '实例ID',
          },
          product_type: {
            label: '产品',
            dropdown: true,
            items: [],
          },
          created_at: getTimeRangeFilter({ label: '时间范围', field: 'created_at' }),
        },
        responseData: this.responseData,
      }),
      userTotal: 0,
      placeholder: '默认为产品名称搜索',
    }
  },
  created () {
    this.initSidePageTab('user-detail')
    this.list.fetchData()
  },
  methods: {
    getParam () {
      const ret = {
        ...this.getParams,
      }
      return ret
    },
  },
}
</script>
