<template>
  <a-form :form="form.fc" v-bind="formItemLayout">
    <a-form-item label="客户ID">
      <a-input placeholder="请输入客户ID" v-decorator="decorators.name" />
    </a-form-item>
    <a-form-item :label="$t('common.description')">
      <a-textarea :auto-size="{ minRows: 1, maxRows: 3 }" v-decorator="decorators.description" :placeholder="$t('common_367')" />
    </a-form-item>
    <a-form-item :label="$t('system.text_221')">
      <a-input-password :placeholder="$t('system.text_239')" v-decorator="decorators.password" />
    </a-form-item>
    <a-form-item :label="$t('dictionary.domain')" v-if="isAdminMode">
      <base-select
        resource="domains"
        v-decorator="decorators.domain"
        :params="domainParams"
        filterable
        remote
        version="v1"
        :showSync="true"
        :select-props="{placeholder: $t('system.text_443', [$t('dictionary.domain')]), labelInValue: true }" />
        <div slot="extra">{{$t('system.text_439')}}<help-link :href="domainCreateLink">{{$t('system.text_128')}}</help-link>
      </div>
    </a-form-item>
    <a-form-item label="手机号">
      <a-input placeholder="请输入手机号" v-decorator="decorators.mobile" />
    </a-form-item>
    <a-form-item label="邮箱">
      <a-input placeholder="请输入邮箱" v-decorator="decorators.email" />
    </a-form-item>
    <a-form-item label="合作方式">
      <a-select placeholder="请选择合作方式" v-decorator="decorators.business_type">
        <a-select-option v-for="item of businessTypeOptions" :key="item.value" :value="item.value">
          {{item.name}}
        </a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item :label="$t('scope.text_245')">
      <a-input :placeholder="$t('system.displayname_tip')" v-decorator="decorators.displayname" />
    </a-form-item>
    <!-- <a-form-item :label="$t('system.text_475')">
      <a-switch :checkedChildren="$t('system.text_134')" :unCheckedChildren="$t('system.text_135')" v-decorator="decorators['allow_web_console']" />
      <div slot="extra">{{$t('system.text_481')}}</div>
    </a-form-item> -->
    <!-- <a-form-item :label="$t('system.text_483')">
      <a-switch :checkedChildren="$t('system.text_134')" :unCheckedChildren="$t('system.text_135')" v-decorator="decorators['enable_mfa']" />
      <div slot="extra">{{$t('system.text_484')}}</div>
    </a-form-item> -->
  </a-form>
</template>

<script>
import { mapGetters } from 'vuex'
// import MobileInput from '@/sections/MobileInput'

export default {
  name: 'CreateCustomer',
  // components: { MobileInput },
  data () {
    // const validatePassword = async (rule, value, callback) => {
    //   if (this.minPasswordLen) {
    //     if (value.length < this.minPasswordLen) return callback(new Error(this.$t('system.text_485', [this.minPasswordLen])))
    //     return callback()
    //   }
    //   const manager = new this.$Manager('services', 'v1')
    //   try {
    //     const response = await manager.list({
    //       params: {
    //         type: 'identity',
    //       },
    //     })
    //     const id = response.data.data && response.data.data[0] && response.data.data[0].id
    //     if (id) {
    //       const configRes = await manager.getSpecific({
    //         id,
    //         spec: 'config',
    //       })
    //       const len = configRes.data.config && configRes.data.config.default && configRes.data.config.default.password_minimal_length
    //       if (len) {
    //         this.minPasswordLen = len
    //         if (value.length < len) return callback(new Error(this.$t('system.text_485', [len])))
    //       }
    //     }
    //     return callback()
    //   } catch (error) {
    //     callback()
    //     throw error
    //   }
    // }
    return {
      businessTypeOptions: [
        { name: '定向', value: 1 },
        { name: '竞标', value: 2 },
      ],
      minPasswordLen: null,
      form: {
        fc: this.$form.createForm(this),
      },
      formItemLayout: {
        wrapperCol: {
          span: 21,
          xxl: {
            span: 22,
          },
        },
        labelCol: {
          span: 3,
          xxl: {
            span: 2,
          },
        },
      },
      decorators: {
        name: [
          'name',
          {
            rules: [
              { required: true, message: '请输入客户ID' },
              { pattern: /^(?![a-zA-Z]+$)(?![0-9]+$)[a-zA-Z0-9]{6,22}$/, message: '客户ID必须为6-22个字符的字母+数字组合' },
            ],
          },
        ],
        description: [
          'description',
          {
            rules: [
              { max: 50, message: '备注最大支持50个字符' },
            ],
          },
        ],
        mobile: [
          'mobile',
          {
            // initialValue: { mobile: '', area_code: '86' },
            rules: [
              { required: true, message: '请输入手机号' },
              // { validator: this.checkInternationalMobile },
              { validator: this.checkMobile },
            ],
          },
        ],
        email: [
          'email',
          {
            rules: [
              { type: 'email', message: this.$t('cloudenv.text_250') },
            ],
          },
        ],
        business_type: [
          'business_type',
          {
            rules: [
              { required: true, message: '请选择合作方式' },
            ],
          },
        ],
        displayname: [
          'displayname',
          {
            initialValue: '',
            rules: [{ required: false }],
          },
        ],
        password: [
          'password',
          {
            validateFirst: true,
            rules: [
              { required: true, message: this.$t('system.text_486'), trigger: 'blur' },
              //  12-22位，由字母+数字+符号，区分大小写，禁用空格
              { pattern: /^(?![A-Za-z0-9]+$)(?![0-9~!@#$%^&*?._-]+$)(?![A-Za-z~!@#$%^&*?._-]+$)[a-zA-Z0-9~!@#$%^&*?._-]{12,22}$/, message: '密码必须为12-22位字母+数字+符号组合，禁用空格，注意区分大小写' },
            ],
          },
        ],
        domain: [
          'domain',
          {
            // initialValue: { key: this.$store.getters.userInfo.projectDomainId, label: this.$store.getters.userInfo.projectDomain },
            // validateTrigger: 'blur',
            rules: [
              { required: true, message: this.$t('rules.domain') },
            ],
          },
        ],
        allow_web_console: [
          'allow_web_console',
          {
            initialValue: true,
            valuePropName: 'checked',
          },
        ],
        enable_mfa: [
          'enable_mfa',
          {
            initialValue: false,
            valuePropName: 'checked',
          },
        ],
      },
      domainParams: {
        scope: this.$store.getters.scope,
        limit: 20,
        enabled: true,
      },
    }
  },
  computed: {
    ...mapGetters(['isAdminMode']),
    domainCreateLink () {
      return this.$router.resolve('/domain/create').href
    },
  },
  methods: {
    validateForm () {
      return this.form.fc.validateFields()
    },
    checkMobile (rule, value, callback) {
      if (!value) {
        callback(this.$t('system.text_138'))
      }

      if (!/^[0-9-]{6,14}$/.test(value)) {
        callback(this.$t('validator.phone'))
      }
      callback()
    },
    checkInternationalMobile (rule, value, callback) {
      if (!value.mobile) {
        callback(this.$t('system.text_138'))
      }

      if (!/^[0-9-]{6,14}$/.test(value.mobile)) {
        callback(this.$t('validator.phone'))
      }
      if (!value.area_code) {
        callback(this.$t('system.country_code'))
      }
      callback()
    },
  },
}
</script>
