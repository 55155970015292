<template>
  <div>
    <page-header  :title="$t('cost.text_1')" />
    <page-body>
      <list :id="listId" />
    </page-body>
  </div>
</template>

<script>
import List from './components/List'

export default {
  name: 'OrderIndex',
  components: {
    List,
  },
  data () {
    return {
      listId: 'OrderList',
      isOperation: process.env.VUE_APP_PLATFORM === 'operation',
    }
  },
}
</script>
